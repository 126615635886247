import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Styles.css";
import {
  Button,
  TextField,
  Typography,
  Grid2 as Grid,
  Tooltip,
  IconButton,
  Autocomplete,
  Chip,
  FormControl,
  FormHelperText,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
} from "@mui/material";
import {
  deleteProjectApi,
  getAllAccountUsersAPi,
  projectDefinationApi,
  updateProjectApi,
  updateProjectDefinition,
} from "../services/BackendServices";
import {
  DESCRIPTION_REQUIRED,
  DEVELOPER_REQUIRED,
  FILE_TYPES_REQUIRED,
  PROJECT_NAME_REQUIRED,
} from "./Constants";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppSelector } from "../redux/Hook";
import { useDispatch } from "react-redux";
import KeycloakService from "../utils/keycloakService";
import AddIcon from "@mui/icons-material/Add";

interface ProjectData {
  projectName: string;
  fileTypes: string;
  description: string;
  timestamp: string;
}

interface Option {
  label: string;
  value: string;
}

const options: Option[] = [
  { label: "React", value: "react" },
  { label: "Angular", value: "angular" },
  { label: "Vue", value: "vue" },
  { label: "Svelte", value: "svelte" },
];

interface User {
  userData: {
    userName: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    emailId: string;
    department: string;
    groups: string[];
    roles: string[];
  };
  userId: string;
}

const ProjectDefinition = () => {
  const [submittedData, setSubmittedData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState("");
  const notification = useAppSelector((state) => state.NotificationAlert);
  const dispatch = useDispatch();
  const [developers, setDevelopers] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const handleDeveloperChange = (_event: React.SyntheticEvent, value: any) => {
    let devs =
      developers?.filter(
        (item: any) =>
          item?.userData?.emailId === KeycloakService?.getUserEmail()
      ) || [];

    // setSelectedUsers([...value, ...devs]);

    // Combine the existing value and new developers, ensuring no duplicates
    const combinedUsers = [...value, ...devs];

    // Remove duplicates based on userId
    const uniqueUsers = combinedUsers.filter(
      (user, index, self) =>
        index === self.findIndex((u) => u.userId === user.userId)
    );

    // Update selected users
    setSelectedUsers(uniqueUsers);
    let data = uniqueUsers?.map((user: any) => user.userId);

    formik?.setFieldValue("user_access", data);
  };

  useEffect(() => {
    getProjectDefinations();
    getAccountUsers();
  }, []);

  const formik: any = useFormik({
    initialValues: {
      projectName: "",
      fileTypes: "",
      description: "",
      user_access: [],
    },
    validationSchema: Yup.object({
      projectName: Yup.string().required(PROJECT_NAME_REQUIRED),
      fileTypes: Yup.string().required(FILE_TYPES_REQUIRED),
      description: Yup.string().required(DESCRIPTION_REQUIRED),
      user_access: Yup.array()
        .of(Yup.string().required(DEVELOPER_REQUIRED))
        .min(1, DEVELOPER_REQUIRED),
    }),
    onSubmit: async (values: any) => {
      if (edit) {
        handleUpdateProject(values);
        return;
      }
      const fileTypesFormatted = values?.fileTypes
        ?.split(",")
        ?.map((type: any) => type?.trim());

      const projectData = {
        project_name: values?.projectName,
        metadata: { description: values?.description },
        file_types: fileTypesFormatted,
        user_access: values?.user_access,
      };

      const response = await updateProjectDefinition(
        projectData?.project_name,
        projectData?.metadata,
        projectData?.file_types,
        projectData?.user_access
      );

      if (response.success) {
        getProjectDefinations();
        formik.resetForm();
        setSelectedUsers([]);
      } else {
        dispatch({
          type: "SEND_ALERT",
          data: {
            ...notification,
            enable: true,
            type: "error",
            message: response.message || "File uploaded successfully",
            duration: 3000,
          },
        });
        console.error("Error:", response?.message);
      }
    },
  });

  const handleUpdateProject = async (values: any) => {
    const fileTypesFormatted = values?.fileTypes
      ?.split(",")
      ?.map((type: any) => type.trim());

    const projectData = {
      project_name: values?.projectName,
      metadata: { description: values?.description },
      file_types: fileTypesFormatted,
      user_access: values?.user_access,
    };

    const response = await updateProjectApi(
      id,
      projectData?.project_name,
      projectData?.metadata,
      projectData?.file_types,
      projectData?.user_access
    );

    if (response.success) {
      const timestamp = new Date().toLocaleString();
      getProjectDefinations();
      setEdit(false);
      formik.resetForm();
      dispatch({
        type: "SEND_ALERT",
        data: {
          ...notification,
          enable: true,
          type: "success",
          message: response.message || "Project updated successfully",
          duration: 3000,
        },
      });
      setSelectedUsers([]);
    } else {
      dispatch({
        type: "SEND_ALERT",
        data: {
          ...notification,
          enable: true,
          type: "error",
          message: response.message || "Project updated Failed",
          duration: 3000,
        },
      });
    }
  };

  const getProjectDefinations = async () => {
    setLoading(true);
    const response: any = await projectDefinationApi();

    if (response?.success) {
      setSubmittedData(
        response?.data?.projects.sort((a: any, b: any) =>
          b._id.localeCompare(a._id)
        )
      );
      formik.resetForm();
      setLoading(false);
    } else {
      console.error("Error:", response?.message);
      setLoading(false);
    }
  };

  const getAccountUsers = async () => {
    setLoading(true);
    const response: any = await getAllAccountUsersAPi();

    if (response?.success) {
      const filteredUsers = response?.data?.data?.filter(
        (user: any) =>
          user?.userData?.roles?.includes("llm-developer") ||
          user?.userData?.roles?.includes("llm-reviewer") ||
          user?.userData?.roles?.includes("llm-admin")
      );

      setDevelopers(filteredUsers);
      let devs: any =
        filteredUsers?.filter(
          (item: any) =>
            item?.userData?.emailId === KeycloakService?.getUserEmail()
        ) || [];
      setSelectedUsers(devs);

      let data = devs?.map((user: any) => user.userId);

      formik?.setFieldValue("user_access", data);
      setLoading(false);
    } else {
      console.error("Error:", response?.message);
      setLoading(false);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "project_name",
      headerName: "Project Name",
      minWidth: 150,
      maxWidth: 300,
      flex: 1,
    },
    {
      field: "file_types",
      headerName: "Types of File",
      minWidth: 150,
      maxWidth: 300,
      flex: 1,
      renderCell: (params) => params.value?.join(", "),
    },
    {
      field: "metadata",
      headerName: "Meta Data/Description",
      maxWidth: 300,
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params?.value?.description || ""}>
          <div
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
          >
            {params?.value?.description}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      minWidth: 150,
      maxWidth: 300,
      flex: 1,
      renderCell: (params) => new Date().toLocaleString(params.value),
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      minWidth: 180,
      renderCell: (params: GridRenderCellParams) => (
        <div>
          <IconButton
            color="primary"
            onClick={() => handleEdit(params.row)}
            aria-label="edit"
          >
            <EditNoteIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => handleDelete(params.row)}
            aria-label="Delete"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleEdit = (params: any) => {
    setDialogOpen(true);
    setEdit(true);
    setId(params?._id);
    formik?.setFieldValue("projectName", params?.project_name);
    formik?.setFieldValue("description", params?.metadata?.description);
    formik?.setFieldValue("fileTypes", params?.file_types?.join(","));
    formik?.setFieldValue("user_access", params?.user_access);

    const initialSelection = developers.filter((user: any) =>
      params?.user_access?.includes(user.userId)
    );

    setSelectedUsers(initialSelection);
  };

  const handleDelete = async (params: any) => {
    console.log("handleDelete", params?._id);
    const response = await deleteProjectApi(params?._id);

    if (response.success) {
      dispatch({
        type: "SEND_ALERT",
        data: {
          ...notification,
          enable: true,
          type: "success",
          message: response?.message || "Project Deleted successfully",
          duration: 3000,
        },
      });
      getProjectDefinations();
    } else {
      dispatch({
        type: "SEND_ALERT",
        data: {
          ...notification,
          enable: true,
          type: "error",
          message: response?.message || "Project Delete Failed",
          duration: 3000,
        },
      });
    }
  };
  const [dialodOpen, setDialogOpen] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    formik?.resetForm();
    setEdit(false);
  };
  const handleCloseSubmitDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <Dialog open={dialodOpen} onClose={handleCloseDialog}>
        <DialogTitle>
          <Typography variant="h5" gutterBottom>
            Browse Project
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={formik?.handleSubmit}
            className="project-form-container"
          >
            <Grid
              container
              direction="row"
              wrap="wrap"
              spacing={2}
              size={{ xs: 6 }}
            >
              <Grid size={{ xs: 12, sm: 4 }}>
                <Typography variant="body1">Project Name</Typography>
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <TextField
                  fullWidth
                  size="small"
                  id="projectName"
                  name="projectName"
                  value={formik?.values?.projectName}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik.touched?.projectName &&
                    Boolean(formik?.errors?.projectName)
                  }
                  helperText={
                    formik?.touched?.projectName && formik?.errors?.projectName
                  }
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 4 }}>
                <Typography variant="body1">Types of File</Typography>
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <TextField
                  fullWidth
                  size="small"
                  id="fileTypes"
                  name="fileTypes"
                  value={formik?.values?.fileTypes}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.fileTypes &&
                    Boolean(formik?.errors?.fileTypes)
                  }
                  helperText={
                    formik?.touched?.fileTypes && formik?.errors?.fileTypes
                  }
                />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ marginTop: "4px", color: "red" }}
                >
                  Note: Please use comma(,) after every file type
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, sm: 4 }}>
                <Typography variant="body1">Meta Data/Description</Typography>
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <TextField
                  fullWidth
                  size="small"
                  id="description"
                  name="description"
                  value={formik?.values?.description}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.description &&
                    Boolean(formik?.errors?.description)
                  }
                  helperText={
                    formik?.touched?.description &&
                    typeof formik?.errors?.description === "string"
                      ? formik?.errors?.description
                      : undefined
                  }
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 4 }}>
                <Typography variant="body1">Select Users</Typography>
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched?.user_access &&
                    Boolean(formik.errors?.user_access)
                  }
                >
                  <Autocomplete
                    multiple
                    size="small"
                    options={developers}
                    getOptionLabel={(option: any) =>
                      `${option?.userData?.firstName} ${option?.userData?.lastName}`
                    }
                    value={selectedUsers}
                    onChange={handleDeveloperChange}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value?.map((option, index) => (
                        <Chip
                          label={`${option?.userData?.firstName} ${option?.userData?.lastName}`}
                          {...getTagProps({ index })}
                          disabled={
                            option?.userData?.emailId ===
                            KeycloakService?.getUserEmail()
                              ? true
                              : false
                          }
                        />
                      ))
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?.userId === value?.userId
                    }
                  />
                  {formik?.touched?.user_access &&
                    formik?.errors?.user_access && (
                      <FormHelperText>
                        {formik?.errors?.user_access}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>

              <Grid
                size={{ xs: 12 }}
                alignItems="center"
                display="flex"
                flexDirection="row"
                gap={4}
                justifyContent="center"
              >
                {edit && (
                  <Grid size={{ xs: 3 }}>
                    <Button
                      onClick={() => {
                        setSelectedUsers([]);
                        setEdit(false);
                        setDialogOpen(false);
                        formik?.resetForm();
                      }}
                      color="primary"
                      variant="outlined"
                      fullWidth
                      type="submit"
                    >
                      Cancel Edit
                    </Button>
                  </Grid>
                )}
                <Grid size={{ xs: 4 }} display={"flex"} gap={"5px"}>
                  {!edit ? (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="button"
                      onClick={handleCloseDialog}
                    >
                      Cancel
                    </Button>
                  ) : null}
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    onClick={handleCloseSubmitDialog}
                  >
                    {!edit ? "Update" : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <Box display={"flex"} justifyContent={"right"}>
        <Button
          onClick={() => setDialogOpen(true)}
          color={"primary"}
          variant="contained"
        >
          Add <AddIcon />
        </Button>
      </Box>

      <Grid
        container
        style={{ marginTop: 20 }}
        direction="row"
        wrap="wrap"
        spacing={2}
        size={{ xs: 6 }}
      >
        <DataGrid
          style={{ height: 400, width: "100%" }}
          getRowId={(row) => row?._id}
          rows={submittedData}
          columns={columns}
          loading={loading}
        />
      </Grid>
    </div>
  );
};

export default ProjectDefinition;
