import React, { useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import { PAGE_COUNT_LIST } from "../constants/constants";
import "./Styles.css";

export default function MUITable({
  rows,
  columns,
  tableHeight,
  loading,
  defaultPageSize = 10,
  ...rest
}: {
  rows: any;
  columns: any;
  tableHeight?: any;
  loading?: boolean;
  defaultPageSize?: number;
  [key: string]: any;
}) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: defaultPageSize,
    page: 0,
  });

  return (
    <Box
      className="custom-table-wrapper"
      sx={{ height: tableHeight, width: "100%" }}
    >
      <DataGrid
        isCellEditable={(params: GridCellParams) => false}
        rows={rows}
        columns={columns}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={PAGE_COUNT_LIST}
        {...rest}
        density="compact"
        hideFooter={false}
        loading={loading ?? false}
      />
    </Box>
  );
}
