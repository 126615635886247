import React, { FC } from "react";
import CreateKnowledge from "./pages/CreateKnowledge";
import Dashboard from "./pages/Dashboard";
import Interact from "./pages/Interact";
import KnowledgeAssets from "./pages/KnowledgeAssets";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DescriptionIcon from "@mui/icons-material/Description";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import NoteIcon from "@mui/icons-material/Note";
import ProjectDefinition from "./pages/ProjectDefinition";

interface Route {
  key: string;
  title: string;
  path: string;
  enabled: boolean;
  component: FC<{}>;
  index: number;
  icon: FC<{}>;
}

export const routes: Array<Route> = [
  {
    key: "home",
    title: "Home",
    path: `${process.env.PUBLIC_URL}/home`,
    enabled: true,
    component: Dashboard,
    index: 0,
    icon: HomeIcon,
  },
  {
    key: "project",
    title: "Browse Project",
    path: `${process.env.PUBLIC_URL}/browseProject`,
    enabled: true,
    component: ProjectDefinition,
    index: 4,
    icon: DescriptionIcon,
  },
  //Todo
  // {
  //   key: "createKnowledgeAssets",
  //   title: "Create Knowledge Assets",
  //   path: `${process.env.PUBLIC_URL}/createKnowledgeAssets`,
  //   enabled: true,
  //   component: CreateKnowledge,
  //   index: 3,
  //   icon: MenuBookIcon,
  // },
  {
    key: "exploreknowledgeAssets",
    title: "Browse Knowledge Assets",
    path: `${process.env.PUBLIC_URL}/browseKnowledgeAssets`,
    enabled: true,
    component: KnowledgeAssets,
    index: 1,
    icon: SearchIcon,
  },
  {
    key: "interact",
    title: "Ask Awgment",
    path: `${process.env.PUBLIC_URL}/askAwgment`,
    enabled: true,
    component: Interact,
    index: 2,
    icon: AutoAwesomeOutlinedIcon,
  },

  /* Add additional routes in Routes.ts as required following the above format: */
];
