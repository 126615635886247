export const HTMLComponent = async (assetName: string, data: Blob) => {
  const blobUrl = URL.createObjectURL(data);

  const newWindow: any = window.open("", "", "width=800,height=600");
  newWindow.document.write(`
    <html>
      <head>
        <title>${assetName}</title>
      </head>
      <body>
        <iframe width="100%" height="100%" src="${blobUrl}" frameborder="0"></iframe>
      </body>
    </html>
  `);
  newWindow.document.close();
  URL.revokeObjectURL(blobUrl);
};
