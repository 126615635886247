import React from "react";
import "./Styles.css";
import Awgment from "../assets/icons/augment-logo.svg";
import homeIcon from "../assets/icons/Awgment_Homepage_image.png";

const Dashboard = () => {
  return (
    <>
      <div className="home-header-text">
        <div>
          AWGMENT
          <img
            src={Awgment}
            alt="comapny-logo"
            className="dashboard-awgment-logo"
          />
        </div>
        <div>
          <img src={homeIcon} alt="home-icon" className="dashboard-home-icon" />
        </div>
      </div>
    </>
  );
};

export default Dashboard;

/* Control Flow: index -> App -> Wrapper -> Header -> SideNav -> NavListItems(Dashboard) -> Dashboard */
