export const TEMPLATE_UPLOAD =
  "/dms/v1/documents?documentTypeId=1194254867397447680";
export const TEMPLATE_DOWNLOAD = "/dms/v1/documents";
export const GENERATE_OTP = "";
export const VERIFY_OTP = "";

export const RUNTIME_FORM_ENDPOINT = "/form-runtime/v1/forms";
export const RUNTIME_FORM_DATA_ENDPOINT = "/form-runtime/v1/form-data";
export const DESIGNTIME_FORM_ENDPOINT = "/form-modeler/v1/forms";

export const MODELER_FORM_ENDPOINT = "/form-modeler/v1/forms";

export const MENU = "/accounts/v1/roles/menu";
export const ALL_MENUS = "/accounts/v1/menus";

export const PROPOSALS_ENDPOINT = "/insurpro-backend/v1/proposals";

export const GET_USERS_ENDPOINT = "/insurpro-backend/v1/users?group=";

export const DASHBOARD_TABLE_ENDPOINT =
  "/insurpro-backend/v1/dashboard/proposals?";

export const DASHBOARD_CHARTS_PREMIUM_ENDPOINT =
  "/insurpro-backend/v1/dashboard/product/premium?";

export const DASHBOARD_CHARTS_CATEGORY_PREMIUM_ENDPOINT =
  "/insurpro-backend/v1/dashboard/product/premium-category?";

export const DASHBOARD_CHARTS_PROPOSAL_ENDPOINT =
  "/insurpro-backend/v1/dashboard/proposals/category?";

export const DASHBOARD_CHARTS_COMMISSION_ENDPOINT =
  "/insurpro-backend/v1/dashboard/product/commission?";

export const DASHBOARD_CHARTS_RENEWAL_ENDPOINT =
  "/insurpro-backend/v1/dashboard/product/upcoming-renewals";

export const DASHBOARD_CHARTS_SERVICE_MANAGER_ENDPOINT =
  "/insurpro-backend/v1/dashboard/service-manager/performance?";

export const GET_ALL_USERS = "/insurpro-backend/v1/all-users";

export const UPLOAD_ENDPOINT = "/awgment-llm/upload";
export const INTERACT_CHAT_ENDPOINT = "/awgment-llm/chat/vector_db";
export const INTERACT_CHAT_KNOWLEDGE_ENDPOINT =
  "/awgment-llm/chat/knowledge_asset";
export const GET_KNOWLEDGE_ASSETS = "/awgment-llm/knowledge_assets_user";
export const PREVIEW_SAS = "/awgment-llm/preview?asset_id=";
export const PREVIEW_COBOL = "/awgment-llm/preview?asset_id=";
export const PREVIEW = "/awgment-llm/preview?asset_id=";
export const DELETE_ASSETS = "/awgment-llm/delete_record";
export const PROJECT_NAME = "/awgment-llm/project_names";
export const DOWNLOAD_ASSET = "/awgment-llm/download?asset_id=";
export const EDIT_ASSET_NAME = "/awgment-llm/edit";
export const UPDATE_PROJECT_DEFINITION = "/awgment-llm/project_definition";
export const GET_PROJECT_DEFINITION = "/awgment-llm/get_project_definitions";
export const GET_FILE_TYPES = "/awgment-llm/get_file_types";
export const PROJECT_DEFINATIONS = "/awgment-llm/get_project_definitions";
export const GET_ALL_ACCOUNT_USERS =
  "/accounts/v1/users?only-mandatory-fields=false";
